import React from 'react';

const Question = ({
    categoryIndex,
    questionIndex,
    question,
    answer,
    value,
    answered,
    handleClick,
    image = null
}) => {

    const handleQuestionClick = () => {
        handleClick(categoryIndex, questionIndex, question, answer, image);
    };

    if (answered) {
        return <div className="question"></div>;
    }

    return (
        <div className="question" onClick={handleQuestionClick}>
            <p>{value}</p>
        </div>
    );
};

export default Question;
