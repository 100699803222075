import React, { useState, useEffect } from 'react';
import CurrentQuestion from './CurrentQuestion';
import Category from './Category'

const Main = ({ questionSetUrl }) => {
    const [categories, setCategories] = useState([]);
    const [currentQuestionData, setCurrentQuestionData] = useState({});
    const [showQuestion, setShowQuestion] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        fetch(questionSetUrl)
            .then((response) => response.json())
            .then((data) => {
                setCategories(data)
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [questionSetUrl]);

    const handleShowQuestion = (categoryIndex, questionIndex, question, answer, image) => {
        console.log(categories, categoryIndex, questionIndex)
        const updatedCategories = [...categories];
        const question2 = updatedCategories[categoryIndex].questions[questionIndex];
        updatedCategories[categoryIndex].questions[questionIndex].answered = true;
        setCategories(updatedCategories);
        setShowQuestion(true);
        setCurrentQuestionData(question2 || {})
    };

    const handleCloseQuestion = () => {
        setShowQuestion(false);
        setShowAnswer(false);
    };

    const handleShowAnswer = () => {
        setShowAnswer(true);
    };

    const getCategories = () => {
        return categories.map((category, i) => {
            console.log(category)
            return <Category
                key={i}
                categoryIndex={i}
                categoryName={category.name}
                questions={category.questions}
                handleClick={handleShowQuestion}
            />
    });
    };

    return (
        <div>
            <CurrentQuestion
                showQuestion={showQuestion}
                showAnswer={showAnswer}
                handleClick={handleShowAnswer}
                handleClose={handleCloseQuestion}
                questionData={currentQuestionData}
            />
            <div className="board">{getCategories()}</div>
        </div>
    );
};

export default Main;

