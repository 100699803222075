import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';  // Importing the main App component

var getQueryVariable = function (variable) {
    var query = window.location.search.substring(1);
    var params = query.split("&").reduce( function(parameters, value) {
        var param = value.split("=");
        parameters[param[0]] = param[1];
        return parameters;
    }, {});

    return params[variable];
};

var questionSet = (getQueryVariable("questions") ? getQueryVariable("questions") : 'questions1');

var questionUrl = '/' + questionSet + '.json';

// Create a root DOM element and render the App component
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main questionSetUrl={questionUrl} />
  </React.StrictMode>
);

// Optional: Performance monitoring
// reportWebVitals();
