import React from 'react';


const YoutubeEmbed = ({ youtubeSlug }) => {
    return <iframe style={{display: "table-cell", margin: "auto"}} width="1120" height="630" src={`https://www.youtube.com/embed/${youtubeSlug}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>;
}

// maxWidth: "60vw", maxHeight: "60vh", height: "auto", display: "block", margin: "auto"

const CurrentQuestion = ({ showQuestion, showAnswer, handleClick, handleClose, questionData = {} }) => {

    const handleStatementClick = () => {
        if (showAnswer) {
            handleClose();
        } else {
            handleClick();
        }
    };

    if (!showQuestion) {
        return <div></div>;
    }
    const { question, answer, imageQuestion, imageAnswer, youtubeSlugQuestion, youtubeSlugAnswer } = questionData;


    return !showAnswer ? (
        <div className="current-question" onClick={handleStatementClick}>
            <div>
                <p>{question}</p>
            </div>
            {
                youtubeSlugQuestion && <div>
                    <YoutubeEmbed youtubeSlug={youtubeSlugQuestion}/>
                </div>
            }
            {imageQuestion &&
                <div>
                    <img src={imageQuestion} />
                </div>
            }
        </div>
    ): (
        <div className="current-question" onClick={handleStatementClick}>
            <div>
                <p>{answer}</p>
            </div>
            {
                youtubeSlugAnswer && <div>
                    <YoutubeEmbed youtubeSlug={youtubeSlugAnswer}/>
                </div>
            }

            {imageAnswer &&
                <div>
                    <img src={imageAnswer} />
                </div>
            }
        </div>
    )
}


export default CurrentQuestion;
