import React from 'react';
import Question from './Question';

const Category = ({ categoryName, questions = [], categoryIndex, handleClick = () => {} }) => {
    const getQuestions = () => {
        return questions.map((question, i) => {
            console.log(question.image);

            return <Question
                key={i}
                value={question.value}
                question={question.question}
                answer={question.answer}
                answered={question.answered}
                handleClick={handleClick}
                categoryIndex={categoryIndex}
                questionIndex={i}
                image={question.image}
                questionData={question}
            />
    });
    };
    return (
        <div className="category">
            <div className="category-header">
                <p>{categoryName}</p>
            </div>
            {getQuestions()}
        </div>
    );
};

export default Category;
